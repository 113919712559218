<template>
  <b-row>
    <b-col class="register-success">
      <div class="register-success__logo broker" />

      <main>
        <h1 class="register-success__title">
          Olá Corretor, seus dados estão sendo analisados!
        </h1>
      </main>

      <footer>
        <b-link :to="{ name: 'login' }">
          <span class="register-success__login-return">
            Voltar para tela de login
          </span>
        </b-link>
      </footer>
    </b-col>

    <b-col class="register-background">
      <div class="register-background-text">
        <h1 class="register-background-text__title">
          Compartilhe imóveis e ganhe comissões rápidas e fáceis
          <span class="register-background-text__title--broker">!</span>
        </h1>
        <p class="register-background-text__paragraph">
          Simples, fácil e seguro para qualquer pessoa investir no mercado
          imobiliário.
        </p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BLink } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
  },
}
</script>

<style lang="scss">
@import '../sass/variables.scss';
@import '../sass/register-background-text.scss';
@import '../sass/register-success.scss';

.register-background {
  display: none;

  @media (min-width: 1055px) {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-image: url('~@/assets/images/pages/background_broker.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
  }
}
</style>
